import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { NotificationPreference } from "@src/lib/interface";
import { FC, Fragment, useEffect, useState } from "react";

import { getNotificationPreference, setNotificationPreference } from "./api";
import { MyNotificationProps } from "./model";
import { PreferenceItem } from "./preferenceItem";
import { Response, ResponseCodes } from "../../utils/response";

import "./style.scss";

const NotificationPreferencePage: FC<MyNotificationProps> = () => {
  const [preferences, setPreferences] = useState({
    sms: false,
    push: false,
    off: false,
    long_code_sms: true,
    urgentShiftNotification: {
      isOn: false,
      snoozedOn: null,
      snoozeForDays: null,
    },
    priorityAccessPush: false,
    aTeamPushNotification: false,
  });

  const updateWorkerCache = useUpdateWorkerCache();

  const [loading, setLoading] = useState(false);

  const aTeamFlag = useCbhFlag(CbhFeatureFlag.A_TEAM_HCP, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.A_TEAM_HCP],
  });

  /**
   * Do stuff on component mount
   */
  useEffect(() => {
    getUserNotificationPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update agent in store with latest notification preferences
   * @param notificationPreferences
   */
  const updateSession = (notificationPreferences: NotificationPreference) => {
    updateWorkerCache({ notificationPreferences });
  };

  /**
   * Fetches user's preferences and updates loader & preference & agent store states
   */
  const getUserNotificationPreferences = async () => {
    setLoading(true);
    try {
      const response: Response = await getNotificationPreference();
      setPreferences(response.data.notificationPreferences);
      updateSession(response.data.notificationPreferences);
    } catch (ex) {
      // Ignore
    }
    setLoading(false);
  };

  /**
   * Updates preference and updates preference & agent store states
   * @param newValue of type {key: string, value: boolean},
   * key can be any of "push", "sms", or "off"
   */
  const updatePreference = async (newValue) => {
    setLoading(true);
    try {
      const response: Response = await setNotificationPreference({
        notificationPreference: { ...newValue },
      });
      if (response.code === ResponseCodes.Success) {
        setPreferences(response.data.notificationPreferences);
        updateSession(response.data.notificationPreferences);
      }
    } catch (ex) {
      // Ignore
    }
    setLoading(false);
  };

  /**
   * Constructs props for all Preference Items
   */
  const createPreferenceProps = () => {
    return [
      {
        name: "Push Notification",
        preferenceKey: "push",
        isBusy: loading,
        initialValue: preferences.push,
        confirmAlertProps: {
          header: `"Clipboard Health" \n would like to send you`,
          subHeader: `Notifications may include alerts, sounds, icons badges. These can be configured in settings`,
          buttonProps: {
            positiveName: `Allow`,
            negativeName: `Don't Allow`,
          },
        },
        onUpdate: updatePreference,
      },
      {
        name: "SMS",
        preferenceKey: "sms",
        isBusy: loading,
        initialValue: preferences.sms,
        confirmAlertProps: {
          header: "Sign up for SMS Shift Alerts ?",
          message: `These notifications help you to stay on top of shifts for our facilities. Message and data rates may apply. Text HELP for help. Text STOP to cancel. Msg freq varies. For healthcare professional terms:
          <a href='https://clipboardhealth.com/professional-terms'>
          https://clipboardhealth.com/professional-terms</a>`,
          buttonProps: {
            positiveName: "Yes",
            negativeName: "No",
          },
        },
        onUpdate: updatePreference,
      },
      {
        name: "Off",
        preferenceKey: "off",
        isBusy: loading,
        initialValue: preferences.off,
        confirmAlertProps: {
          header: "Are you sure?",
          subHeader: `If you turn notification off then we won't be able to provide you important updates about your shifts`,
          buttonProps: {
            positiveName: `Yes, I'm sure`,
            negativeName: `No, I'm not sure`,
          },
        },
        onUpdate: updatePreference,
      },
    ];
  };
  /**
   * Constructs Props for urgentShiftsNotification
   */
  const UrgentShiftsNotificationProps = {
    name: "Urgent Shift Notifications",
    preferenceKey: "urgentShiftNotification",
    isBusy: loading,
    initialValue: preferences.urgentShiftNotification?.isOn,
    confirmAlertProps: {
      header: `"Clipboard Health" \n would like to send you`,
      subHeader: `Urgent Shift Notifications may include alerts, sounds, icons badges.`,
      buttonProps: {
        positiveName: `Allow`,
        negativeName: `Don't Allow`,
      },
    },
    onUpdate: updatePreference,
  };

  /**
   * Constructs Props for PriorityAccessNotifications
   */
  const PriorityAccessNotificationProps = {
    name: "Priority Access Notifications",
    preferenceKey: "priorityAccessPush",
    isBusy: loading,
    initialValue: preferences.priorityAccessPush,

    confirmAlertProps: {
      header: `"Clipboard Health" \n would like to send you`,
      subHeader: "Notifications about Priority Access shifts that you have early access to.",
      buttonProps: {
        positiveName: `Allow`,
        negativeName: `Don't Allow`,
      },
    },
    onUpdate: updatePreference,
  };

  /**
   * Constructs Props for ATeamNotifications
   */
  const ATeamNotificationProps = {
    name: "Priority Plus Notifications",
    preferenceKey: "aTeamPushNotification",
    isBusy: loading,
    initialValue: preferences.aTeamPushNotification,

    confirmAlertProps: {
      header: `"Clipboard Health" \n would like to send you`,
      subHeader: "Notifications when your Priority Plus workplaces post new shifts.",
      buttonProps: {
        positiveName: `Allow`,
        negativeName: `Don't Allow`,
      },
    },
    onUpdate: updatePreference,
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Privacy" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Notification Preferences</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-card-content-main custom-notification-preference">
        <IonItem lines="none" className="ion-margin-top">
          <IonLabel className="ion-top-label">Account and shifts updates</IonLabel>
        </IonItem>
        <IonCard>
          <IonCardContent class="ion-no-padding custom-padding-notif-card">
            {createPreferenceProps().map((preferenceProp) => {
              return <PreferenceItem {...preferenceProp} key={preferenceProp.preferenceKey} />;
            })}
          </IonCardContent>
        </IonCard>
        <br />

        <IonCardContent class="ion-no-padding">
          {<PreferenceItem {...UrgentShiftsNotificationProps} />}
        </IonCardContent>
        <IonItem lines="none">
          <IonText className="text-muted">
            Be notified when high-earning Urgent Shifts become available to book.
          </IonText>
        </IonItem>
        <br />
        {[ATeamHcpStatus.OFF, ATeamHcpStatus.PRIORITY_ACCESS].includes(aTeamFlag.status) && (
          <>
            <IonCardContent class="ion-no-padding">
              <PreferenceItem {...PriorityAccessNotificationProps} />
            </IonCardContent>
            <IonItem lines="none">
              <IonText className="text-muted">
                Receive updates when you get early access to shifts posted by facilities that have
                given you a high rating.
              </IonText>
            </IonItem>
          </>
        )}

        {[ATeamHcpStatus.A_TEAM, ATeamHcpStatus.PRIORITY_ACCESS].includes(aTeamFlag.status) && (
          <>
            <IonCardContent class="ion-no-padding">
              <PreferenceItem {...ATeamNotificationProps} />
            </IonCardContent>
            <IonItem lines="none">
              <IonText className="text-muted">
                Receive notifications when your Priority Plus facilities post new shifts
              </IonText>
            </IonItem>
          </>
        )}

        <div className="dot-spinner">
          {loading && <IonSpinner name="dots" className="dot-spinner" />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export { NotificationPreferencePage };
